.Plan{
    position: absolute;
    top: $header-height;
    transition: opacity 0.5s;

    width: 1470px;
    height: 980px;

    background-color: rgba(255, 255, 255, 0.5);
    
    .PlanImage{
        width: 1470px;
        height: 980px;
        transition: opacity 0.5s;
    }

    &[data-visible='false']{
        opacity: 0;
        pointer-events: none;
    }


    &[data-visible='true']{
        opacity: 1;
        transition-delay: 0.2;
    }



    &[data-loading='false']{
        .PlanImage{
            opacity: 1;
            transition-delay: 0.2;
        }
    }


    &[data-loading='true']{
        .PlanImage{
            opacity: 0;
            transition: 0s;
        }
    }


    .PlanClose{
        cursor: pointer;
        padding: 7px;
        position: absolute;
        right: 4px;
        top: 0;
        transform: scale(0.9);
        color: $color-background;
        :hover{
            opacity: 0.8;
        }
    }

    .PlanUI{
        position: absolute;
        top: 0px;
        left: 0px;
        color: $color-background;
        font-style: normal;
        

        .PlanTitle{
            position: absolute;
            left: 460px;
            top: 30px;
            font-size: 24px;
            font-family: inherit;
            color: $color-background;
            font-family: 'OptimaLTPro-Roman';
            //font-weight: 600;
            line-height: 0.5em;
            letter-spacing: 0.05em;
            width: 500px;
        }

        .PlanPrice{
            position: absolute;
            left: 460px;
            top: 50px;
            font-family: 'FreightSansProLight-Regular';
            color: $color-background;
            font-size: 24px;
        }

        .PlanUITop{
            transition: opacity 0.25s;
        }
        
        &[data-zoomed="true"]{
            .PlanUITop{
                opacity: 0;
           } 
        }

        .PlanZoomIn, .PlanZoomOut{
            position: absolute;
            top: 760px;
            left: 1380px;
            transform: scale(0.7);
            cursor: pointer;
            &:hover{
                filter: brightness(1.25);
            }
        }

        .PlanZoomIn{
            left: 1380px;
        }

        .PlanZoomOut{
            left: 1320px;
        }
        
        .SubmitButton{
            position: absolute;
            left: 1220px;
            top: 5px;
            padding: 10px;
            font-size: 17px;
            width: 130px;
            color: $color-background;
            border: 1px solid $color-background;
        }
    }

}
.SidePanel{
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(100% - 100px);

    @include no-select;
        
    .SidePanelInnerShadow{
        position: absolute;
        width: 0px;
        left: 0px;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    }

    .SidePanelItems{
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $color-background;
        background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
        background-size: 100% 30px;
        background-repeat: repeat-x;
    }

}
.Selection{
    $width: 160px;
    margin-left: 190px;
    color: white;
    width: width;
    position: relative;
    min-height: 100px;
    cursor: pointer;
    
    /*
    &:hover{
        opacity: 0.85;
    };
    */

    .SelectionLabel{
        letter-spacing: 0.05em;
        width: 130px;
        font-size: 16px;
        @include font-body-bold;
    }

    .SelectionItem{
        font-size: 18px;
    }
  

    .SelectionIcon{
        font-size: 40px;
        position: absolute;
        right: 150px;
        top: -7px;
        pointer-events: none;
        
        //display: none; // Here

        .SelectionIconClose{
            transform: translate(120px, -28px) scale(0.45) rotate(-90deg);
        }

        .SelectionIconOpen{
            transform: translate(106px, 2px) scale(0.45) rotate(90deg);
        }


        div{
           position: absolute;
           left: 0px;
           top: 0px;
           //background-color: red;
           
        }
    }

    .SelectionEmpty{
        visibility: hidden;
        width: $width;
        height: 8px;
    }

    .SelectionSelect, .SelectionOptions{
        border: 1px solid white;
        position: absolute;
        width: $width;
    }

    .SelectionSelect{
        padding: 8px;
        padding-left: 10px;
        padding-right: 10px;
    }

    &[data-expanded='true']{
        .SelectionSelect{
            display: none;
        }

        .SelectionIconOpen{
            display: none;
        }
    }

    &[data-expanded='false']{
        .SelectionOptions{
            display: none;
        }
        .SelectionIconClose{
            display: none;
        }
    }


    .SelectionOptions{
        background-color: $color-background;
        z-index: 100;
        
        .SelectionOption{
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;

            &:first-child{
                padding-top:20px;
            }

            &:last-child{
                padding-bottom:20px;
            }
        }
    }

   
}
.App{
    width: 100vw;
    height: 100vh;
}


@font-face {
    font-family: 'OptimaLTPro-Roman';
    src: url("../../fonts/OptimaLTPro-Roman.otf");
}

@font-face {
    font-family: 'FreightSansProLight-Regular';
    src: url('../../fonts/FreightSansProLight-Regular.otf');
}

@font-face {
    font-family: 'FreightSansProSemibold-Regular';
    src: url('../../fonts/FreightSansProSemibold-Regular.otf');
}

@mixin font-header {
    font-family: 'OptimaLTPro-Roman';
    font-style: normal;
    font-weight: 600;
}

@mixin font-body {
    font-family: 'FreightSansProLight-Regular';
    font-style: normal;
    font-weight: 400;
}

@mixin font-body-bold {
    font-family: 'FreightSansProSemibold-Regular';
    font-style: normal;
    font-weight: 400;
}
body{
    font-family: 'FreightSansProLight-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: $color-links;
    letter-spacing: 0.03em;
}

a{
    text-decoration: inherit;
    color: inherit;
}

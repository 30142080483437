.NavMasterplanItem{
    position: relative;
    top: -60px;
    left: 20px;
    color: $color-links;
    font-size: 18px;
    font-weight: 300;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1.35em;

    &:hover{
        color: white;
    }

    &[data-selected="true"]{
        color: white;

        .NavMasterplanItemIcon{
            img:nth-child(1){
                display: none;
            }
            img:nth-child(2){
                display: inline-block;
            }
        }
    }

    .NavMasterplanItemIcon{
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;

        transform: scale(1) translate(0%, 0%);

        img:nth-child(1){
            display: inline-block;
        }
        img:nth-child(2){
            display: none;
        }
    }

}